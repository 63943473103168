@import "src/vars";

.header {
    position: fixed;
    top: 0;
    width: 100%;
    box-sizing: border-box;
    height: $header-height;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 40px;
    color: white;
    z-index: 2;
    background: $black;

    border-bottom: 1px solid $dark;

    &__section {
        display: flex;
        align-items: center;
    }

    &__link {
        height: 40px;
        padding: 8px 10px;
        border-radius: 80px;
        border: 2px solid transparent;
        margin-right: 4px;
        box-sizing: border-box;

        &--selected {
            border: 2px solid $dark-gray;
        }

        &:last-child {
            margin-right: 0;
        }
    }

    &__links {
        margin-right: 40px;
    }

    &__statistics {
        width: 120px;
        margin-right: 24px;
        height: 40px;

        span {
            padding-left: 8px;
        }
    }

    &__connect {
        font-weight: bold;
        font-size: 12px;
        line-height: 16px;
        padding: 8px 16px;
        cursor: pointer;
    }

    &__address-button {
        height: 40px;
        padding: 8px 10px 8px 8px;

        &__title {
            padding: 0 8px;
        }
    }

    &__menu {
        margin-left: 16px;
    }

    @media only screen and (max-width: $mobile-width) {
        padding: 14px 16px;
    }
}
