@import "src/vars";

.network-changer {
  margin-right: 24px;
  height: 40px;

  &__value {
    padding: 0 8px;

    &--wrong {
      color: $red;
    }
  }
}

.network-changer-wrong {
  background-color: $red-contrast;
  border: 1px solid $red-contrast;
  color: white;

  border-radius: 14px;
  cursor: pointer;
  user-select: none;
  display: flex;
  align-items: center;
  padding: 8px 10px;
  margin-right: 24px;
}