.main-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &__tokens-title {
    padding-top: 24px;
    font-size: 20px;
  }

  &__tokens {
    display: flex;
    padding-bottom: 20px;
  }

  li {
    padding-bottom: 10px;
  }

  &__token {
    margin: 0 10px 0 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &__text {
      display: flex;
      font-size: 16px;
      align-items: center;
      justify-content: center;
      white-space: nowrap;
    }

    &__add {
      font-size: 12px;
      padding-top: 6px;
      cursor: pointer;

      &:hover {
        opacity: 0.7;
      }
    }
  }
}

.link {
  cursor: pointer;
}